import { writable } from '../../../_snowpack/pkg/svelte/store.js';
import { CosmosClient } from '../../../_snowpack/pkg/@azure/cosmos.js'
import { login, cosmos } from "../config/config.js";
import { InteractiveBrowserCredential as ibc } from '../../../_snowpack/pkg/@azure/identity.js'

export const searchResults = writable(null);

export const getSearchResults = async (qry) => {
  
  const { endpoint, databaseId, containerId } = cosmos
  const aadCredentials = new ibc(login)
  const client = new CosmosClient({ endpoint, aadCredentials })
  const database = client.database(databaseId)
  const container = database.container(containerId)

  await container.items
    .query(qry)
    .fetchAll()
    .then(({ resources: items }) => searchResults.set(items))
    .catch((err) => console.error(err))

}
