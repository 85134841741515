/* src/components/Header.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	space
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let h1;
	let t1;
	let nav;

	return {
		c() {
			h1 = element("h1");
			h1.textContent = "Document Search";
			t1 = space();
			nav = element("nav");

			nav.innerHTML = `<a href="#/">Home</a> 
  <a href="#/Preferences">Preferences</a>`;
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			insert(target, t1, anchor);
			insert(target, nav, anchor);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(h1);
			if (detaching) detach(t1);
			if (detaching) detach(nav);
		}
	};
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Header;