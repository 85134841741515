/* src/Content.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "../_snowpack/pkg/svelte/internal.js";

import Router from '../_snowpack/pkg/svelte-spa-router.js';
import routes from './routes.js';
import Header from './components/Header.svelte.js';
import Footer from './components/Footer.svelte.js';

function create_fragment(ctx) {
	let header1;
	let header0;
	let t0;
	let main;
	let router;
	let t1;
	let footer1;
	let footer0;
	let current;
	header0 = new Header({});
	router = new Router({ props: { routes } });
	footer0 = new Footer({});

	return {
		c() {
			header1 = element("header");
			create_component(header0.$$.fragment);
			t0 = space();
			main = element("main");
			create_component(router.$$.fragment);
			t1 = space();
			footer1 = element("footer");
			create_component(footer0.$$.fragment);
		},
		m(target, anchor) {
			insert(target, header1, anchor);
			mount_component(header0, header1, null);
			insert(target, t0, anchor);
			insert(target, main, anchor);
			mount_component(router, main, null);
			insert(target, t1, anchor);
			insert(target, footer1, anchor);
			mount_component(footer0, footer1, null);
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(header0.$$.fragment, local);
			transition_in(router.$$.fragment, local);
			transition_in(footer0.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(header0.$$.fragment, local);
			transition_out(router.$$.fragment, local);
			transition_out(footer0.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(header1);
			destroy_component(header0);
			if (detaching) detach(t0);
			if (detaching) detach(main);
			destroy_component(router);
			if (detaching) detach(t1);
			if (detaching) detach(footer1);
			destroy_component(footer0);
		}
	};
}

class Content extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Content;