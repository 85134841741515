/* src/routes/Preferences.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	add_render_callback,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_data,
	set_input_value,
	set_style,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { preferences, resetPreferences } from '../stores/preferences/preferences.js';
import matchTypes from '../stores/preferences/matchTypes.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[8] = list[i];
	child_ctx[9] = list;
	child_ctx[10] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	child_ctx[15] = list;
	child_ctx[16] = i;
	return child_ctx;
}

// (7:0) {#if $preferences}
function create_if_block(ctx) {
	let section;
	let p0;
	let t1;
	let button;
	let t3;
	let details0;
	let summary0;
	let t5;
	let p1;
	let t7;
	let input;
	let t8;
	let t9;
	let details1;
	let summary1;
	let t11;
	let p2;
	let t13;
	let table0;
	let thead0;
	let t19;
	let tbody0;
	let t20;
	let details2;
	let summary2;
	let t22;
	let p3;
	let t24;
	let table1;
	let thead1;
	let t34;
	let tbody1;
	let mounted;
	let dispose;
	let each_value_2 = /*$preferences*/ ctx[0].docs;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_1[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let each_value = /*$preferences*/ ctx[0].props;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			section = element("section");
			p0 = element("p");
			p0.textContent = "Please see the sections below for preferences you can modify to improve your experience. If, at any time, you would like you can reset the preferences to default by using the button below.  Settings will be saved in your browser so you will not have to reset them every time you search.";
			t1 = space();
			button = element("button");
			button.textContent = "Reset Preferences";
			t3 = space();
			details0 = element("details");
			summary0 = element("summary");
			summary0.textContent = "Case Sensitivity";
			t5 = space();
			p1 = element("p");
			p1.textContent = "All searches are case sensitive. Typically, most text data in this system is upper case, so all text entered into the search boxes will be converted to upper case before it is sent to the server for searching. If you'd like to disable that behavior, uncheck the box below.";
			t7 = space();
			input = element("input");
			t8 = text("Convert all search terms to upper case");
			t9 = space();
			details1 = element("details");
			summary1 = element("summary");
			summary1.textContent = "Document Types";
			t11 = space();
			p2 = element("p");
			p2.textContent = "Choose document types you'd like to include in your queries.";
			t13 = space();
			table0 = element("table");
			thead0 = element("thead");

			thead0.innerHTML = `<tr><th>ID</th> 
        <th>Document Type</th> 
        <th>Include</th></tr>`;

			t19 = space();
			tbody0 = element("tbody");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t20 = space();
			details2 = element("details");
			summary2 = element("summary");
			summary2.textContent = "Search Options";
			t22 = space();
			p3 = element("p");
			p3.textContent = "These options change which fields you can search by, what kind of match is used for those fields, and which document types you return in your searches. Please note that all searches are case-sensitive and regardless of whether or not a field is set to 'Show' it will only appear in the search results if it has data in it. Blank values will be omitted from the output.";
			t24 = space();
			table1 = element("table");
			thead1 = element("thead");

			thead1.innerHTML = `<tr><th>ID</th> 
        <th>Field Name</th> 
        <th>Search</th> 
        <th>Match</th> 
        <th>Show</th></tr>`;

			t34 = space();
			tbody1 = element("tbody");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(input, "type", "checkbox");
		},
		m(target, anchor) {
			insert(target, section, anchor);
			append(section, p0);
			append(section, t1);
			append(section, button);
			insert(target, t3, anchor);
			insert(target, details0, anchor);
			append(details0, summary0);
			append(details0, t5);
			append(details0, p1);
			append(details0, t7);
			append(details0, input);
			input.checked = /*$preferences*/ ctx[0].upperCase;
			append(details0, t8);
			insert(target, t9, anchor);
			insert(target, details1, anchor);
			append(details1, summary1);
			append(details1, t11);
			append(details1, p2);
			append(details1, t13);
			append(details1, table0);
			append(table0, thead0);
			append(table0, t19);
			append(table0, tbody0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				if (each_blocks_1[i]) {
					each_blocks_1[i].m(tbody0, null);
				}
			}

			insert(target, t20, anchor);
			insert(target, details2, anchor);
			append(details2, summary2);
			append(details2, t22);
			append(details2, p3);
			append(details2, t24);
			append(details2, table1);
			append(table1, thead1);
			append(table1, t34);
			append(table1, tbody1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(tbody1, null);
				}
			}

			if (!mounted) {
				dispose = [
					listen(button, "click", resetPreferences),
					listen(input, "change", /*input_change_handler*/ ctx[1])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$preferences, matchTypes*/ 1) {
				input.checked = /*$preferences*/ ctx[0].upperCase;
			}

			if (dirty & /*$preferences*/ 1) {
				each_value_2 = /*$preferences*/ ctx[0].docs;
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_2(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(tbody0, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_2.length;
			}

			if (dirty & /*$preferences, matchTypes*/ 1) {
				each_value = /*$preferences*/ ctx[0].props;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(tbody1, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(section);
			if (detaching) detach(t3);
			if (detaching) detach(details0);
			if (detaching) detach(t9);
			if (detaching) detach(details1);
			destroy_each(each_blocks_1, detaching);
			if (detaching) detach(t20);
			if (detaching) detach(details2);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (32:6) {#each $preferences.docs as d}
function create_each_block_2(ctx) {
	let tr;
	let td0;
	let t0_value = /*d*/ ctx[14].id + "";
	let t0;
	let t1;
	let td1;
	let input0;
	let t2;
	let td2;
	let input1;
	let t3;
	let mounted;
	let dispose;

	function input0_input_handler() {
		/*input0_input_handler*/ ctx[2].call(input0, /*each_value_2*/ ctx[15], /*d_index*/ ctx[16]);
	}

	function input1_change_handler() {
		/*input1_change_handler*/ ctx[3].call(input1, /*each_value_2*/ ctx[15], /*d_index*/ ctx[16]);
	}

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			input0 = element("input");
			t2 = space();
			td2 = element("td");
			input1 = element("input");
			t3 = space();
			set_style(input0, "width", "20rem");
			attr(input1, "type", "checkbox");
			set_style(td2, "text-align", "center");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, input0);
			set_input_value(input0, /*d*/ ctx[14].name);
			append(tr, t2);
			append(tr, td2);
			append(td2, input1);
			input1.checked = /*d*/ ctx[14].show;
			append(tr, t3);

			if (!mounted) {
				dispose = [
					listen(input0, "input", input0_input_handler),
					listen(input1, "change", input1_change_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*$preferences*/ 1 && t0_value !== (t0_value = /*d*/ ctx[14].id + "")) set_data(t0, t0_value);

			if (dirty & /*$preferences, matchTypes*/ 1 && input0.value !== /*d*/ ctx[14].name) {
				set_input_value(input0, /*d*/ ctx[14].name);
			}

			if (dirty & /*$preferences, matchTypes*/ 1) {
				input1.checked = /*d*/ ctx[14].show;
			}
		},
		d(detaching) {
			if (detaching) detach(tr);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (73:14) {#each matchTypes as m}
function create_each_block_1(ctx) {
	let option;
	let t_value = /*m*/ ctx[11].text + "";
	let t;
	let option_value_value;

	return {
		c() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*m*/ ctx[11].value;
			option.value = option.__value;
			option.selected = true;
		},
		m(target, anchor) {
			insert(target, option, anchor);
			append(option, t);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(option);
		}
	};
}

// (62:6) {#each $preferences.props as r}
function create_each_block(ctx) {
	let tr;
	let td0;
	let t0_value = /*r*/ ctx[8].id + "";
	let t0;
	let t1;
	let td1;
	let input0;
	let t2;
	let td2;
	let input1;
	let t3;
	let td3;
	let select;
	let t4;
	let td4;
	let input2;
	let t5;
	let mounted;
	let dispose;

	function input0_input_handler_1() {
		/*input0_input_handler_1*/ ctx[4].call(input0, /*each_value*/ ctx[9], /*r_index*/ ctx[10]);
	}

	function input1_change_handler_1() {
		/*input1_change_handler_1*/ ctx[5].call(input1, /*each_value*/ ctx[9], /*r_index*/ ctx[10]);
	}

	let each_value_1 = matchTypes;
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	function select_change_handler() {
		/*select_change_handler*/ ctx[6].call(select, /*each_value*/ ctx[9], /*r_index*/ ctx[10]);
	}

	function input2_change_handler() {
		/*input2_change_handler*/ ctx[7].call(input2, /*each_value*/ ctx[9], /*r_index*/ ctx[10]);
	}

	return {
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			input0 = element("input");
			t2 = space();
			td2 = element("td");
			input1 = element("input");
			t3 = space();
			td3 = element("td");
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			td4 = element("td");
			input2 = element("input");
			t5 = space();
			set_style(input0, "width", "20rem");
			attr(input1, "type", "checkbox");
			set_style(td2, "text-align", "center");
			set_style(select, "width", "100%");
			if (/*r*/ ctx[8].match === void 0) add_render_callback(select_change_handler);
			attr(input2, "type", "checkbox");
			set_style(td4, "text-align", "center");
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, input0);
			set_input_value(input0, /*r*/ ctx[8].name);
			append(tr, t2);
			append(tr, td2);
			append(td2, input1);
			input1.checked = /*r*/ ctx[8].search;
			append(tr, t3);
			append(tr, td3);
			append(td3, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(select, null);
				}
			}

			select_option(select, /*r*/ ctx[8].match, true);
			append(tr, t4);
			append(tr, td4);
			append(td4, input2);
			input2.checked = /*r*/ ctx[8].show;
			append(tr, t5);

			if (!mounted) {
				dispose = [
					listen(input0, "input", input0_input_handler_1),
					listen(input1, "change", input1_change_handler_1),
					listen(select, "change", select_change_handler),
					listen(input2, "change", input2_change_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*$preferences*/ 1 && t0_value !== (t0_value = /*r*/ ctx[8].id + "")) set_data(t0, t0_value);

			if (dirty & /*$preferences, matchTypes*/ 1 && input0.value !== /*r*/ ctx[8].name) {
				set_input_value(input0, /*r*/ ctx[8].name);
			}

			if (dirty & /*$preferences, matchTypes*/ 1) {
				input1.checked = /*r*/ ctx[8].search;
			}

			if (dirty & /*matchTypes*/ 0) {
				each_value_1 = matchTypes;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (dirty & /*$preferences, matchTypes*/ 1) {
				select_option(select, /*r*/ ctx[8].match);
			}

			if (dirty & /*$preferences, matchTypes*/ 1) {
				input2.checked = /*r*/ ctx[8].show;
			}
		},
		d(detaching) {
			if (detaching) detach(tr);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*$preferences*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*$preferences*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $preferences;
	component_subscribe($$self, preferences, $$value => $$invalidate(0, $preferences = $$value));

	function input_change_handler() {
		$preferences.upperCase = this.checked;
		preferences.set($preferences);
	}

	function input0_input_handler(each_value_2, d_index) {
		each_value_2[d_index].name = this.value;
		preferences.set($preferences);
	}

	function input1_change_handler(each_value_2, d_index) {
		each_value_2[d_index].show = this.checked;
		preferences.set($preferences);
	}

	function input0_input_handler_1(each_value, r_index) {
		each_value[r_index].name = this.value;
		preferences.set($preferences);
	}

	function input1_change_handler_1(each_value, r_index) {
		each_value[r_index].search = this.checked;
		preferences.set($preferences);
	}

	function select_change_handler(each_value, r_index) {
		each_value[r_index].match = select_value(this);
		preferences.set($preferences);
	}

	function input2_change_handler(each_value, r_index) {
		each_value[r_index].show = this.checked;
		preferences.set($preferences);
	}

	return [
		$preferences,
		input_change_handler,
		input0_input_handler,
		input1_change_handler,
		input0_input_handler_1,
		input1_change_handler_1,
		select_change_handler,
		input2_change_handler
	];
}

class Preferences extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Preferences;