/* src/routes/Search.svelte generated by Svelte v3.59.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { getSearchResults, searchResults } from '../stores/search/search.js';
import matchTypes from '../stores/preferences/matchTypes.js';
import { preferences } from '../stores/preferences/preferences.js';
import SearchResults from '../components/SearchResults.svelte.js';
import Spinner from '../components/Spinner.svelte.js';

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	child_ctx[11] = list;
	child_ctx[12] = i;
	return child_ctx;
}

// (88:0) {#each mypref as p}
function create_each_block(ctx) {
	let p;
	let label;
	let b;
	let t0_value = /*p*/ ctx[10].name + "";
	let t0;
	let t1;
	let code;
	let t2_value = matchTypes.find(func).text + "";
	let t2;
	let label_for_value;
	let t3;
	let br;
	let t4;
	let input;
	let input_id_value;
	let mounted;
	let dispose;

	function func(...args) {
		return /*func*/ ctx[6](/*p*/ ctx[10], ...args);
	}

	function input_input_handler() {
		/*input_input_handler*/ ctx[7].call(input, /*each_value*/ ctx[11], /*p_index*/ ctx[12]);
	}

	return {
		c() {
			p = element("p");
			label = element("label");
			b = element("b");
			t0 = text(t0_value);
			t1 = space();
			code = element("code");
			t2 = text(t2_value);
			t3 = space();
			br = element("br");
			t4 = space();
			input = element("input");
			attr(label, "for", label_for_value = /*p*/ ctx[10].id);
			attr(input, "id", input_id_value = /*p*/ ctx[10].id);
			attr(input, "placeholder", "Type a value to search and hit enter.");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, label);
			append(label, b);
			append(b, t0);
			append(label, t1);
			append(label, code);
			append(code, t2);
			append(p, t3);
			append(p, br);
			append(p, t4);
			append(p, input);
			set_input_value(input, /*p*/ ctx[10].value);

			if (!mounted) {
				dispose = [
					listen(input, "change", /*handleChange*/ ctx[3]),
					listen(input, "input", input_input_handler)
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*mypref*/ 4 && t0_value !== (t0_value = /*p*/ ctx[10].name + "")) set_data(t0, t0_value);
			if (dirty & /*mypref*/ 4 && t2_value !== (t2_value = matchTypes.find(func).text + "")) set_data(t2, t2_value);

			if (dirty & /*mypref*/ 4 && label_for_value !== (label_for_value = /*p*/ ctx[10].id)) {
				attr(label, "for", label_for_value);
			}

			if (dirty & /*mypref*/ 4 && input_id_value !== (input_id_value = /*p*/ ctx[10].id)) {
				attr(input, "id", input_id_value);
			}

			if (dirty & /*mypref*/ 4 && input.value !== /*p*/ ctx[10].value) {
				set_input_value(input, /*p*/ ctx[10].value);
			}
		},
		d(detaching) {
			if (detaching) detach(p);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (103:0) {#if mypref.length === 0}
function create_if_block_2(ctx) {
	let t;

	return {
		c() {
			t = text("No search fields enabled. Please check preferences.");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (107:0) {#if searching}
function create_if_block_1(ctx) {
	let spinner;
	let current;
	spinner = new Spinner({ props: { message: 'Getting data...' } });

	return {
		c() {
			create_component(spinner.$$.fragment);
		},
		m(target, anchor) {
			mount_component(spinner, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(spinner.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(spinner.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(spinner, detaching);
		}
	};
}

// (111:0) {#if error}
function create_if_block(ctx) {
	let code;

	return {
		c() {
			code = element("code");
			code.textContent = "An error has ocurred while searching.";
		},
		m(target, anchor) {
			insert(target, code, anchor);
		},
		d(detaching) {
			if (detaching) detach(code);
		}
	};
}

function create_fragment(ctx) {
	let t0;
	let t1;
	let t2;
	let t3;
	let searchresults;
	let current;
	let each_value = /*mypref*/ ctx[2];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block0 = /*mypref*/ ctx[2].length === 0 && create_if_block_2(ctx);
	let if_block1 = /*searching*/ ctx[0] && create_if_block_1(ctx);
	let if_block2 = /*error*/ ctx[1] && create_if_block(ctx);
	searchresults = new SearchResults({});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t0 = space();
			if (if_block0) if_block0.c();
			t1 = space();
			if (if_block1) if_block1.c();
			t2 = space();
			if (if_block2) if_block2.c();
			t3 = space();
			create_component(searchresults.$$.fragment);
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, t0, anchor);
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t1, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, t2, anchor);
			if (if_block2) if_block2.m(target, anchor);
			insert(target, t3, anchor);
			mount_component(searchresults, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*mypref, handleChange, matchTypes*/ 12) {
				each_value = /*mypref*/ ctx[2];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(t0.parentNode, t0);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (/*mypref*/ ctx[2].length === 0) {
				if (if_block0) {
					
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(t1.parentNode, t1);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*searching*/ ctx[0]) {
				if (if_block1) {
					if (dirty & /*searching*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(t2.parentNode, t2);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*error*/ ctx[1]) {
				if (if_block2) {
					
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(t3.parentNode, t3);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block1);
			transition_in(searchresults.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block1);
			transition_out(searchresults.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t0);
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t1);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(t2);
			if (if_block2) if_block2.d(detaching);
			if (detaching) detach(t3);
			destroy_component(searchresults, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $preferences;
	component_subscribe($$self, preferences, $$value => $$invalidate(5, $preferences = $$value));
	let searching = false;
	let error = false;
	let mypref, upperCase, docs, props;

	const handleChange = async e => {
		//set base query
		let sql = ['select top 15'];

		//set fields to select
		let fields = ['c["74"]', 'c["72"]'];

		//push any additional show fields to our sql
		$preferences.props.filter(x => x.show).forEach(x => fields.push(`c["${x.id}"]`));

		//push our fields to our sql
		sql.push(fields.join(','));

		//push the base from clause
		sql.push('from c where');

		//set base conditions
		let conditions = ['c["74"] <> ""', 'c["72"] <> ""'];

		//get prefs defined by user
		let userprefs = $preferences.props.filter(x => x.search).filter(x => x.value !== '').map(x => ({
			id: x.id,
			op: x.match.match('%') ? 'like' : '=',
			val: x.match.replace('=', x.value)
		}));

		//push conditions based on user prefs
		userprefs.forEach(x => {
			conditions.push(`c["${x.id}"] ${x.op} @p${x.id}`);
		});

		//define param values based on user prefs
		let params = userprefs.map(x => ({
			name: `@p${x.id}`,
			value: upperCase ? x.val.toUpperCase() : x.val
		}));

		//add param and conditions for doc type filter
		let docTypes = $preferences.docs.filter(x => x.show);

		if (docTypes.length > 0) {
			conditions.push(`c["72"] in (${docTypes.map(x => `"${x.id}"`).join(',')})`);
		} // params.push({ name: '@p72', value: docTypes.map(x=>`"${x.id}"`).join(',') })

		//push conditions to our sql
		sql.push(conditions.join(' and '));

		//create our query object to pass to cosmos
		let query = { query: sql.join(' '), parameters: params };

		$$invalidate(0, searching = true);

		await getSearchResults(query).then(_ => $$invalidate(0, searching = false)).catch(err => {
			$$invalidate(0, searching = false);
			$$invalidate(1, error = true);
			console.error(err);
		});
	};

	const func = (p, x) => x.value === p.match;

	function input_input_handler(each_value, p_index) {
		each_value[p_index].value = this.value;
		(($$invalidate(2, mypref), $$invalidate(4, props)), $$invalidate(5, $preferences));
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$preferences*/ 32) {
			$: upperCase = $preferences.upperCase;
		}

		if ($$self.$$.dirty & /*$preferences*/ 32) {
			$: docs = $preferences.docs;
		}

		if ($$self.$$.dirty & /*$preferences*/ 32) {
			$: $$invalidate(4, props = $preferences.props);
		}

		if ($$self.$$.dirty & /*props*/ 16) {
			$: $$invalidate(2, mypref = props.filter(x => x.search));
		}
	};

	return [
		searching,
		error,
		mypref,
		handleChange,
		props,
		$preferences,
		func,
		input_input_handler
	];
}

class Search extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Search;