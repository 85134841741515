export default [
  { value: "", show: false, search: false, match: "=", id: 1, name: "Vendor Name"},
  { value: "", show: true, search: false, match: "=%", id: 2, name: "Invoice Number"},
  { value: "", show: true, search: false, match: "=", id: 3, name: "Invoice Date"},
  { value: "", show: false, search: false, match: "=", id: 4, name: "Amount"},
  { value: "", show: false, search: false, match: "=", id: 5, name: "Company Code"},
  { value: "", show: false, search: false, match: "=", id: 6, name: "Batch Number"},
  { value: "", show: false, search: false, match: "=", id: 7, name: "Vendor Code"},
  { value: "", show: false, search: false, match: "=", id: 8, name: "Check Date"},
  { value: "", show: false, search: false, match: "=", id: 9, name: "Check Number"},
  { value: "", show: false, search: false, match: "=", id: 10, name: "Voucher Number"},
  { value: "", show: false, search: false, match: "=", id: 11, name: "Check Amount"},
  { value: "", show: false, search: false, match: "=%", id: 12, name: "Terminal Number"},
  { value: "", show: true, search: true, match: "=%", id: 13, name: "Bill of Lading Number"},
  { value: "", show: true, search: true, match: "%=%", id: 14, name: "Ship Date"},
  { value: "", show: true, search: false, match: "=", id: 15, name: "Shipper ID "},
  { value: "", show: true, search: false, match: "=", id: 16, name: "Consignee ID"},
  { value: "", show: true, search: false, match: "%=%", id: 17, name: "Bill to ID "},
  { value: "", show: true, search: false, match: "=", id: 18, name: "Driver "},
  { value: "", show: false, search: false, match: "=", id: 19, name: "Order Status"},
  { value: "", show: false, search: false, match: "=", id: 20, name: "Customer PO #"},
  { value: "", show: false, search: false, match: "=", id: 21, name: "PRO Number"},
  { value: "", show: true, search: false, match: "=", id: 22, name: "Billed Date"},
  // { value: "", show: false, search: false, match: "=", id: 23, name: "TIME"},
  // { value: "", show: false, search: false, match: "=", id: 24, name: "DATE"},
  { value: "", show: false, search: false, match: "=", id: 25, name: "BARCODE FAILED"},
  { value: "", show: false, search: false, match: "=", id: 26, name: "CUSTOMER ID"},
  { value: "", show: false, search: false, match: "=", id: 27, name: "AUTO INDEX FAILED"},
  { value: "", show: false, search: false, match: "=", id: 31, name: "Apply Date"},
  { value: "", show: false, search: false, match: "=", id: 32, name: "Cust Ship Ticket"},
  { value: "", show: false, search: false, match: "=", id: 37, name: "Account Code"},
  { value: "", show: false, search: false, match: "=", id: 38, name: "Voucher Date"},
  { value: "", show: false, search: false, match: "=", id: 39, name: "Social Security #"},
  { value: "", show: false, search: false, match: "=", id: 40, name: "Last Name"},
  { value: "", show: false, search: false, match: "=", id: 41, name: "First Name"},
  { value: "", show: false, search: false, match: "=", id: 42, name: "Qualcomm Barcode #"},
  { value: "", show: false, search: false, match: "=", id: 51, name: "Year"},
  { value: "", show: false, search: false, match: "=", id: 55, name: "Fiscal Year"},
  { value: "", show: false, search: false, match: "=", id: 56, name: "Terminal"},
  { value: "", show: false, search: false, match: "=", id: 57, name: "Month"},
  { value: "", show: false, search: false, match: "=", id: 58, name: "TYPE"},
  { value: "", show: true, search: true, match: "%=%", id: 70, name: "Created"},
  // { value: "", show: false, search: false, match: "=", id: 72, name: "DocumentTypeId"},
  { value: "", show: true, search: false, match: "=", id: 73, name: "PageCount"},
  // { value: "", show: false, search: false, match: "=", id: 74, name: "DocumentStoreFileID"},
]