import { writable } from '../../../_snowpack/pkg/svelte/store.js';
import defaultPrefs from './defaultPrefs.js';

export const preferences = writable(
  localStorage.preferences
    ? JSON.parse(localStorage.preferences)
    : defaultPrefs);

preferences.subscribe(val =>
  localStorage.preferences = JSON.stringify(val));

export const resetPreferences = () =>
  preferences.set(defaultPrefs);